<template>
  <Transition name="fade-slide-top">
    <div @keydown.esc="close" class="Modal-dropdown" ref="modalDropdown" v-show="isOpen">
      <slot></slot>
    </div>
  </Transition>
</template>
<script lang="ts" setup>
import { getFirstFocusableElement } from '~/helpers/accessibility';

export interface ModalDropdownProps {
  eventElement?: HTMLElement | null;
  modelValue?: boolean;
  noFocusOnOpen?: boolean;
}

const props = withDefaults(defineProps<ModalDropdownProps>(), {
  eventElement: undefined,
  modelValue: false,
  noFocusOnOpen: false
});

const emit = defineEmits(['modal-dropdown:model-value']);

const modalDropdown = ref();
const isOpen = ref<boolean>(props.modelValue);

const close = () => {
  if (isOpen.value) {
    isOpen.value = false;
    emit('modal-dropdown:model-value', false);
    props.eventElement?.focus();
  }
};

const focusFirstFocusableElement = () => {
  if (!modalDropdown.value) {
    return;
  }
  const firstFocusableElement = getFirstFocusableElement(modalDropdown.value);
  if (firstFocusableElement) {
    useFocus(firstFocusableElement as HTMLElement, { initialValue: true });
  }
};

defineExpose({
  close
});

onClickOutside(modalDropdown, (e) => {
  nextTick(() => {
    if (e.target !== props.eventElement) {
      close();
    }
  });
});

watch(
  () => props.modelValue,
  (value) => {
    isOpen.value = value;
  }
);

watch(
  () => isOpen.value,
  (value) => {
    if (value && !props.noFocusOnOpen) {
      nextTick(() => {
        focusFirstFocusableElement();
      });
    }
  }
);
</script>
<style lang="scss" scoped>
@use 'ModalDropdown.scss';
</style>
