<template>
  <fieldset :class="['Field-set', { 'Field-set--booking': variant === 'booking' }]">
    <span :class="{ 'accessibility-sr-only': legendIsSROnly }">
      <ux-atoms-typo :color="colorPerVariant" :text="legend" :variant="textPerVariant" as="legend" class="Field-set__legend" />
    </span>
    <div class="Field-set__container">
      <slot />
    </div>
  </fieldset>
</template>

<script setup lang="ts">
export interface FieldSetProps {
  legend: string;
  legendIsSROnly?: boolean;
  variant?: 'booking' | 'default';
}

const props = withDefaults(defineProps<FieldSetProps>(), {
  legend: '',
  legendIsSROnly: false,
  variant: 'default'
});

const colorPerVariant = computed(() => {
  return props.variant === 'booking' ? 'dark' : 'light';
});

const textPerVariant = computed(() => {
  return props.variant === 'booking' ? 'text-heading-01' : 'text-small';
});
</script>
<style lang="scss" scoped>
@use 'FieldSet.scss';
</style>
