<template>
  <Transition name="fade">
    <div @click.self="onClose" @keydown.esc="onClose($event)" class="Modal__backdrop" v-if="isOverlay && isOpen"></div>
  </Transition>
  <div class="Modal__element" ref="modal">
    <Transition :name="transition">
      <div
        :class="[
          'Modal__container',
          `Modal__container--${size}`,
          { 'Modal__container--centered': isOverlay },
          { 'Modal__container--has-top-gap': hasTopGap },
          positionClass
        ]"
        @click.self="onClose"
        @keydown.esc="onClose"
        ref="modalContainer"
        tabindex="0"
        v-show="isOpen"
      >
        <div
          :aria-hidden="!isOpen"
          :aria-labelledby="labelledBy"
          :class="['Modal', `Modal--${size}`, { 'Modal--has-top-gap': hasTopGap }]"
          aria-modal="true"
          role="dialog"
        >
          <div
            :class="[
              'Modal__header',
              { 'Modal__header--no-header': disableHeaderHeight },
              { 'Modal__header--logo': headerLogo, 'Modal__header--empty': !headerText },
              { 'Modal__header--no-separator': hasNoSeparator }
            ]"
            v-if="hasHeader"
          >
            <ux-atoms-button
              :class="['Modal__close-button', { 'Modal__close-button--right': buttonPosition === 'right', 'mt-6': disableHeaderHeight }]"
              :no-border="buttonNoBorder"
              @click="onClose"
              type="button"
              variant="action"
            >
              <ux-atoms-icon :description="$t('ux.molecules.modal.close.label')" :name="iconName" size="m" />
            </ux-atoms-button>
            <slot name="header" v-if="$slots.header" />
            <ux-atoms-typo
              :as="headerTextAs as keyof HTMLElementTagNameMap"
              :id="hasLabelId ? 'LabelForModalContent' : ''"
              :text="headerText"
              color="inherit"
              v-else-if="headerText"
              variant="text-medium"
            />
          </div>
          <div
            :class="['Modal__content', { 'Modal__content--no-y-space': noYSpace }, { 'Modal__content--full-height': hasContentFullHeight }]"
            :id="id"
          >
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script lang="ts" setup>
// Component based on wellness packages/front/src/components/ux/molecules/WlnsModal/WlnsModal.vue
import { addTrapFocusToElement, focusableElements, modalTitleElements, removeTrapFocusToElement } from '~/helpers/accessibility';

export interface ModalProps {
  buttonNoBorder?: boolean;
  buttonPosition?: 'left' | 'right';
  disableHeaderHeight?: boolean;
  eventElement?: HTMLElement | null;
  hasContentFullHeight?: boolean;
  hasHeader?: boolean;
  hasLabelId?: boolean;
  hasNoSeparator?: boolean;
  hasTopGap?: boolean;
  headerLogo?: boolean;
  headerText?: string;
  headerTextAs?: keyof HTMLElementTagNameMap;
  iconName?: 'arrow-left' | 'chevron-left' | 'close';
  id?: string;
  isSubModal?: boolean;
  modelValue: boolean;
  noYSpace?: boolean;
  position?: 'bottom';
  size?: 'full' | 'half' | 'large' | 'medium' | 'medium-cut' | 'small';
  transition?: 'fade' | 'side' | 'slide';
}

const props = withDefaults(defineProps<ModalProps>(), {
  buttonNoBorder: false,
  buttonPosition: 'left',
  disableHeaderHeight: false,
  eventElement: undefined,
  full: false,
  hasContentFullHeight: false,
  hasHeader: true,
  hasLabelId: false,
  hasNoSeparator: false,
  hasTopGap: false,
  headerLogo: false,
  headerText: undefined,
  headerTextAs: undefined,
  iconName: 'close',
  id: undefined,
  isSubModal: false,
  modelValue: false,
  noYSpace: false,
  position: undefined,
  side: false,
  size: 'full',
  transition: 'fade'
});

const isOpen = ref<boolean>(props.modelValue);
const modal = ref<HTMLDivElement>();
const modalContainer = ref<HTMLDivElement>();
const SCROLL_DISABLED = 'overflow-hidden';
const labelledBy = ref<string | undefined>(undefined);
const uniqueTitleId = useId();

const emit = defineEmits(['update:model-value', 'closeModal']);

const onClose = (e: Event) => {
  const subModals = modalContainer.value?.querySelectorAll('.Modal__container');
  emit('closeModal', e);

  let eventIsFromSubModal = false;

  subModals?.forEach((subModal) => {
    if (subModal.contains(e.target as HTMLElement)) {
      eventIsFromSubModal = true;
    }
  });

  if (e.target !== modalContainer.value || (modalContainer.value?.contains(e.target as HTMLElement) && !eventIsFromSubModal)) {
    emit('update:model-value', false);

    isOpen.value = false;

    if (!props.isSubModal) {
      document.querySelector('body')?.classList.remove(SCROLL_DISABLED);
    }

    useFocus(props.eventElement, { initialValue: true });
  }
};

const isOverlay = computed(() => {
  return ['half', 'large', 'medium', 'medium-cut', 'small'].includes(props.size);
});

const positionClass = computed(() => {
  return props.position ? `Modal__container--${props.position}` : '';
});

const getLabelledBy = () => {
  // Finds if there's a title element in the .Modal__header,
  // then sets a unique id to it
  // also sets the modal's labelledBy to this unique id if the element exists
  const header = modal.value?.querySelector('.Modal__header');
  const headerTitle = header?.querySelectorAll(modalTitleElements)[0] as HTMLElement;
  if (headerTitle) {
    headerTitle?.setAttribute('id', uniqueTitleId);
    labelledBy.value = uniqueTitleId;
  }
};

watch(
  () => props.modelValue,
  (value) => {
    isOpen.value = value;
    nextTick(() => {
      if (value) {
        document.querySelector('body')?.classList.add(SCROLL_DISABLED);
      }
    });
  },
  { immediate: true }
);

// Allows to focus the first element of the modal. Has to be done at opening as element cannot be focused if it's not visible
watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      nextTick(() => {
        const firstFocusableElement = modal.value?.querySelectorAll(focusableElements)[0] as HTMLElement;
        useFocus(firstFocusableElement, { initialValue: true });
      });
    }
    const element = document.querySelector('body');

    if (!value && element && element.matches(`.${SCROLL_DISABLED}`) && !props.isSubModal) {
      element.classList.remove(SCROLL_DISABLED);
    }
  }
);

onMounted(() => {
  getLabelledBy();
  addTrapFocusToElement(modal.value);
});

onUnmounted(() => {
  removeTrapFocusToElement(modal.value);

  const value = isOpen.value;
  const element = document.querySelector('body');

  if (!value && element && element.matches(`.${SCROLL_DISABLED}`) && !props.isSubModal) {
    element.classList.remove(SCROLL_DISABLED);
  }
});
</script>
<style lang="scss" scoped>
@use 'Modal.scss';
</style>
