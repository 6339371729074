import { useWindowSize } from '@vueuse/core';

import { breakpoints } from '~/helpers/breakpoints';

export const useCurrentWindowSize = () => {
  const { width } = useWindowSize();

  const isDesktop = computed(() => {
    return width.value !== Infinity ? width.value >= breakpoints.lg : false;
  });

  const isTablet = computed(() => {
    return width.value !== Infinity ? width.value >= breakpoints.md : false;
  });

  const isMobile = computed(() => {
    return width.value !== Infinity ? width.value < breakpoints.md : false;
  });

  return {
    isDesktop,
    isMobile,
    isTablet
  };
};
